import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { ComputedPaymentTypeValues } from '@ads-bread/shared/bread/util';
import { PaymentProductType } from '@ads-bread/shared/bread/codecs';
import IntroScreen, {
  IntroSubHeading,
  IntroTitle,
  PoweredBySubHeading,
  formattingValues,
} from '../../components/pages/intro/Intro';
import { useFormattedNumber } from '../../lib/hooks/useFormattedNumber';
import { ButtonClickedEvents } from '../../lib/analytics';
import { useMerchantConfig } from '../../components/MerchantContext';
import { Button } from '../../components/Button';
import { RouteMachineServiceScreenProps } from '../../components/RouteMachineService';
import { Disclaimers } from '../pages/intro/Disclaimers';
import { MarketingPromo } from '../MarketingPromo';
import { classNames } from '../../lib/classNames';

export const IntroSideBySideScreen: FunctionComponent<RouteMachineServiceScreenProps> =
  (props) => {
    return (
      <IntroScreen {...props}>
        {({
          hasSplitpay,
          hasInstallments,
          sdkInstallmentsPaymentTypeValues,
          isDefaultOrder,
          sdkSplitPayPaymentTypeValues,
          synthesizedSplitPayPaymentValues,
          useSyntheticPaymentTypeValues,
          exampleInstallmentsPaymentTypeValues,
        }) => {
          const splitPayPaymentTypeValues = useSyntheticPaymentTypeValues
            ? synthesizedSplitPayPaymentValues
            : sdkSplitPayPaymentTypeValues;

          const installmentsPaymentTypeValues = useSyntheticPaymentTypeValues
            ? exampleInstallmentsPaymentTypeValues
            : sdkInstallmentsPaymentTypeValues;

          return (
            <>
              <IntroTitle>
                <FormattedMessage
                  defaultMessage="Pay over time"
                  description="Intro screen heading"
                />
              </IntroTitle>

              <IntroSubHeading>
                {hasSplitpay && hasInstallments ? (
                  <FormattedMessage
                    defaultMessage="Make flexible payments with our pay-over-time plans"
                    description="ADS Intro screen subheading SxS"
                  />
                ) : hasSplitpay ? (
                  <FormattedMessage
                    defaultMessage="Split your purchase into 4 interest-free payments"
                    description="ADS Intro screen subheading splitpay only"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="With flexible monthly payment plans"
                    description="ADS Intro screen subheading installments only"
                  />
                )}
              </IntroSubHeading>

              <PoweredBySubHeading />

              <SideBySide
                isDefaultOrder={isDefaultOrder}
                splitPayPaymentTypeValues={splitPayPaymentTypeValues}
                installmentsPaymentTypeValues={installmentsPaymentTypeValues}
              />

              <Button
                analyticsEventName={ButtonClickedEvents.GetStarted}
                onClick={props.forward}
              >
                <FormattedMessage
                  defaultMessage="Get started"
                  description="Intro screen button text"
                />
              </Button>
              {MarketingPromo && <MarketingPromo />}
              <Disclaimers
                hasInstallments={hasInstallments}
                hasSplitPay={hasSplitpay}
                installmentsPaymentTypeValues={
                  exampleInstallmentsPaymentTypeValues
                }
                splitPayPaymentTypeValues={splitPayPaymentTypeValues}
              />
            </>
          );
        }}
      </IntroScreen>
    );
  };

const PaymentTypeCard: FunctionComponent<{
  productType: PaymentProductType;
  paymentTypeValues: ComputedPaymentTypeValues;
  isSingleCard?: boolean;
}> = ({ productType, paymentTypeValues, isSingleCard = true }) => {
  const { aprFormatter, formatCurrency } = useFormattedNumber();
  const { disclosureSymbolInstallments, disclosureSymbolSplitpay } =
    useMerchantConfig();

  return (
    <div
      className={classNames(
        'rounded-lg bg-extra-light-grey border-theme-primary border-solid border-2 text-center w-full',
        isSingleCard ? 'px-4 py-6' : 'px-3 py-4 min-w-[160px] max-w-[185px]'
      )}
      data-testid={`${productType}-card`}
    >
      <h3 className="items-center justify-center text-lg font-bold leading-normal mb-4 pb-1">
        <FormattedMessage
          defaultMessage="{productType, select, INSTALLMENTS {Installments} other {SplitPay}}<sup>{productType, select, INSTALLMENTS {{disclosureSymbolInstallments}} other {{disclosureSymbolSplitpay}}}</sup>"
          description="Intro side-by-side card heading"
          values={{
            ...formattingValues,
            productType,
            disclosureSymbolInstallments,
            disclosureSymbolSplitpay,
          }}
        />
        <hr className="border-theme-primary border-solid border-[1px] mt-2 mx-auto max-w-[135px]" />
      </h3>

      <div
        className={classNames(
          'flex flex-wrap justify-center content-center',
          isSingleCard ? 'gap-8 flex-row' : 'flex-col'
        )}
      >
        <div
          className={classNames(
            'flex-1 shrink-0 grow flex-row flex justify-center flex-nowrap whitespace-nowrap',
            !isSingleCard ? 'mb-3' : ''
          )}
        >
          <div className="inline-block">
            <h4 className="text-xs">
              <FormattedMessage
                defaultMessage="{productType, select, INSTALLMENTS {Starting at} other {Always interest-free}}"
                description="Intro side-by-side interest rate subheading"
                values={{
                  productType,
                }}
              />
            </h4>

            <div className="text-lg">
              <span className="">
                <FormattedMessage
                  defaultMessage="{rate} APR"
                  description="Intro side-by-side interest rate"
                  values={{
                    rate: aprFormatter(paymentTypeValues.minInterestRateBPS),
                  }}
                />
              </span>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            'flex-1 shrink-0 grow-0 flex-nowrap whitespace-nowrap hidden xs:block',
            !isSingleCard
              ? 'border-theme-primary border-opacity-40 border-b-[1px] mb-3 mx-auto w-20'
              : 'border-theme-primary border-opacity-40 border-l-[1px]',
            productType !== 'INSTALLMENTS' && 'border-none'
          )}
        ></div>

        <div className="flex-1 shrink-0 grow flex-row flex justify-center flex-nowrap">
          <div className="inline-block">
            <h4 className="text-xs">
              <FormattedMessage
                defaultMessage="{productType, select, INSTALLMENTS {Monthly payments as low as} other {4 interest-free payments of}}"
                description="Intro side-by-side payment subheading"
                values={{
                  productType,
                }}
              />
            </h4>
            <div className="text-lg">
              <FormattedMessage
                defaultMessage="{amount}"
                description="Intro side-by-side payment amount"
                values={{
                  amount: formatCurrency(paymentTypeValues.promoOfferAmount),
                  length: paymentTypeValues.promoOfferFrequency.length,
                  designator: paymentTypeValues.promoOfferFrequency.designator,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CombinedFrame: FunctionComponent<{
  splitPay: ComputedPaymentTypeValues;
  installment: ComputedPaymentTypeValues;
}> = ({ splitPay, installment }) => {
  return (
    <div className="relative">
      <div className="flex">
        <div className="flex items-center justify-center bg-white border-theme-primary border-solid border-2 rounded-full absolute w-[36px] h-[36px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 box-border">
          <span>
            <FormattedMessage
              defaultMessage="OR"
              description="Intro side-by-side or"
            />
          </span>
        </div>

        <div className="flex justify-center space-x-2 w-full">
          <PaymentTypeCard
            productType="INSTALLMENTS"
            paymentTypeValues={installment}
            isSingleCard={false}
          />

          <PaymentTypeCard
            productType="SPLITPAY"
            paymentTypeValues={splitPay}
            isSingleCard={false}
          />
        </div>
      </div>
    </div>
  );
};

type SideBySideProps = {
  isDefaultOrder: boolean;
  splitPayPaymentTypeValues: ComputedPaymentTypeValues | null;
  installmentsPaymentTypeValues: ComputedPaymentTypeValues | null;
};

const SideBySide: FunctionComponent<SideBySideProps> = ({
  splitPayPaymentTypeValues,
  installmentsPaymentTypeValues,
}) => {
  const shouldDisplaySplitPay = splitPayPaymentTypeValues !== null;
  const shouldDisplayInstallments = installmentsPaymentTypeValues !== null;

  return (
    <>
      <div className="mb-4" data-testid="intro-side-by-side">
        {shouldDisplayInstallments && shouldDisplaySplitPay && (
          <CombinedFrame
            splitPay={splitPayPaymentTypeValues}
            installment={installmentsPaymentTypeValues}
          />
        )}
        {shouldDisplayInstallments && !shouldDisplaySplitPay && (
          <PaymentTypeCard
            productType="INSTALLMENTS"
            paymentTypeValues={installmentsPaymentTypeValues}
          />
        )}
        {!shouldDisplayInstallments && shouldDisplaySplitPay && (
          <PaymentTypeCard
            productType="SPLITPAY"
            paymentTypeValues={splitPayPaymentTypeValues}
          />
        )}
      </div>
    </>
  );
};
