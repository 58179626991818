// File: intro-ab-test-2-comp-parity-simplicity/newFile.tsx

import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { InstallmentsCard, SplitPayCard } from '../../pages/alliance';
import IntroScreen, {
  IntroBaseSubHeading,
  IntroTitle,
} from '../pages/intro/Intro';
import { Disclaimers } from '../pages/intro/Disclaimers';
import { MarketingPromo } from '../MarketingPromo';
import { Installments } from '../svgs/Installments';
import { SplitPay } from '../svgs/SplitPay';
import { RouteMachineServiceScreenProps as RouteScreenProps } from '../RouteMachineService';
import { ButtonClickedEvents } from '../../lib/analytics';
import { Button } from '../Button';
import { CombinedFrame } from '../intro-ab-test/intro-side-by-side';

/**
 * If single product is available, we will show the introDefault Installments card stack design.
 * If multiple products are available, we will show the SideBySide card design (test 1 winner).
 */

export const IntroSingleStackDualSideBySideScreen: FunctionComponent<RouteScreenProps> =
  (props) => {
    return (
      <IntroScreen {...props}>
        {({
          hasSplitpay,
          hasInstallments,
          sdkInstallmentsPaymentTypeValues,
          isDefaultOrder,
          sdkSplitPayPaymentTypeValues,
          synthesizedSplitPayPaymentValues,
          useSyntheticPaymentTypeValues,
          exampleInstallmentsPaymentTypeValues,
        }) => {
          const splitPayPaymentTypeValues = useSyntheticPaymentTypeValues
            ? synthesizedSplitPayPaymentValues
            : sdkSplitPayPaymentTypeValues;

          const installmentsPaymentTypeValues = useSyntheticPaymentTypeValues
            ? exampleInstallmentsPaymentTypeValues
            : sdkInstallmentsPaymentTypeValues;

          return (
            <>
              <IntroTitle
                className={
                  hasSplitpay && hasInstallments
                    ? 'mb-8'
                    : hasSplitpay
                    ? 'mb-10'
                    : 'mb-11'
                }
              >
                <FormattedMessage
                  defaultMessage="Pay Over Time"
                  description="Intro screen heading"
                />
              </IntroTitle>

              <IntroBaseSubHeading>
                {hasSplitpay && hasInstallments ? (
                  <FormattedMessage
                    defaultMessage="Make flexible payments with our pay-over-time plans"
                    description="ADS Intro screen subheading SxS"
                  />
                ) : hasSplitpay ? (
                  <FormattedMessage
                    defaultMessage="Split your purchase into 4 interest-free payments"
                    description="ADS Intro screen subheading splitpay only"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="With flexible monthly payment plans"
                    description="ADS Intro screen subheading installmentsonly"
                  />
                )}
              </IntroBaseSubHeading>

              <div
                className="mt-8 mb-8"
                data-testid="intro-single-stack-dual-side-by-side"
              >
                {installmentsPaymentTypeValues && splitPayPaymentTypeValues ? (
                  <>
                    <CombinedFrame
                      splitPay={splitPayPaymentTypeValues}
                      installment={installmentsPaymentTypeValues}
                    />
                  </>
                ) : installmentsPaymentTypeValues ? (
                  <InstallmentsCard
                    isDefaultOrder={isDefaultOrder}
                    paymentTypeValues={installmentsPaymentTypeValues}
                    splitPayPaymentTypeValues={splitPayPaymentTypeValues}
                    installmentIcon={Installments}
                    abTestTwoResultType="intro-single-stack-dual-side-by-side"
                  />
                ) : (
                  splitPayPaymentTypeValues && (
                    <SplitPayCard
                      isDefaultOrder={isDefaultOrder}
                      paymentTypeValues={splitPayPaymentTypeValues}
                      installmentPaymentTypeValues={
                        installmentsPaymentTypeValues
                      }
                      splitPayIcon={SplitPay}
                      abTestTwoResultType="intro-single-stack-dual-side-by-side"
                    />
                  )
                )}
              </div>

              <Button
                analyticsEventName={ButtonClickedEvents.GetStarted}
                onClick={props.forward}
              >
                <FormattedMessage
                  defaultMessage="Get started"
                  description="Intro screen button text"
                />
              </Button>

              {MarketingPromo && <MarketingPromo />}
              <Disclaimers
                hasInstallments={hasInstallments}
                hasSplitPay={hasSplitpay}
                installmentsPaymentTypeValues={
                  exampleInstallmentsPaymentTypeValues
                }
                splitPayPaymentTypeValues={splitPayPaymentTypeValues}
              />
            </>
          );
        }}
      </IntroScreen>
    );
  };
