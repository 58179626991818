import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormikHelpers } from 'formik';
import IntroScreen, {
  IntroBaseSubHeading,
  IntroTitle,
} from '../pages/intro/Intro';
import { MarketingPromo } from '../MarketingPromo';
import { Disclaimers } from '../pages/intro/Disclaimers';
import { RouteMachineServiceScreenProps } from '../RouteMachineService';
import { InstallmentsCard, SplitPayCard } from '../../pages/alliance';
import { Installments } from '../svgs/Installments';
import { SplitPay } from '../svgs/SplitPay';
import { emailValidationSchema } from '../pages/email/Email';
import { TextField } from '../forms/TextField';
import { APIForm } from '../APIForm';
import { useToast } from '../Toast';
import { useMerchantPaymentProducts, useSDKOptions } from '../XPropsContext';
import { useBuyerMachine } from '../BuyerMachineContext';
import { logger } from '../../lib/logger';
import { SubmitError } from '../forms/SubmitError';
import LoadingSpinner from '../LoadingSpinner';
import { ButtonClickedEvents } from '../../lib/analytics';
import { Button } from '../Button';

export const IntroEmailAddedScreen: FunctionComponent<RouteMachineServiceScreenProps> =
  (props) => {
    const intl = useIntl();
    const { closeNotification } = useToast();
    const { isPreview } = useSDKOptions();
    const { email: contextEmail, assignBuyerEmail } = useBuyerMachine();
    const { merchantPaymentProducts } = useMerchantPaymentProducts();

    if (!merchantPaymentProducts?.length) {
      logger.error('Expected merchant payment product to not be empty.');
      return null;
    }

    const initialEmailFormValues = {
      email: contextEmail || '',
    };

    type EmailFormValues = typeof initialEmailFormValues;

    const handleSubmit: (
      values: EmailFormValues,
      formikHelpers: FormikHelpers<EmailFormValues>
    ) => void | Promise<void> = (values) => {
      closeNotification();
      assignBuyerEmail(values.email);
      props.forward();
    };

    return (
      <IntroScreen {...props}>
        {({
          hasSplitpay,
          hasInstallments,
          sdkInstallmentsPaymentTypeValues,
          isDefaultOrder,
          sdkSplitPayPaymentTypeValues,
          synthesizedSplitPayPaymentValues,
          useSyntheticPaymentTypeValues,
          exampleInstallmentsPaymentTypeValues,
        }) => {
          const splitPayPaymentTypeValues = useSyntheticPaymentTypeValues
            ? synthesizedSplitPayPaymentValues
            : sdkSplitPayPaymentTypeValues;

          const installmentsPaymentTypeValues = useSyntheticPaymentTypeValues
            ? exampleInstallmentsPaymentTypeValues
            : sdkInstallmentsPaymentTypeValues;

          return (
            <>
              <IntroTitle>
                <FormattedMessage
                  defaultMessage="Pay Over Time"
                  description="Intro screen heading"
                />
              </IntroTitle>

              <IntroBaseSubHeading>
                {hasSplitpay && hasInstallments ? (
                  <FormattedMessage
                    defaultMessage="Make flexible payments with our pay-over-time plans"
                    description="ADS Intro screen subheading SxS"
                  />
                ) : hasSplitpay ? (
                  <FormattedMessage
                    defaultMessage="Split your purchase into 4 interest-free payments"
                    description="ADS Intro screen subheading splitpay only"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="With flexible monthly payment plans"
                    description="ADS Intro screen subheading installmentsonly"
                  />
                )}
              </IntroBaseSubHeading>

              {installmentsPaymentTypeValues && (
                <InstallmentsCard
                  isDefaultOrder={isDefaultOrder}
                  paymentTypeValues={installmentsPaymentTypeValues}
                  splitPayPaymentTypeValues={splitPayPaymentTypeValues}
                  installmentIcon={Installments}
                  abTestTwoResultType="intro-email-added"
                />
              )}
              {splitPayPaymentTypeValues && (
                <SplitPayCard
                  isDefaultOrder={isDefaultOrder}
                  paymentTypeValues={splitPayPaymentTypeValues}
                  installmentPaymentTypeValues={installmentsPaymentTypeValues}
                  splitPayIcon={SplitPay}
                  abTestTwoResultType="intro-email-added"
                />
              )}

              <APIForm<EmailFormValues>
                initialValues={initialEmailFormValues}
                onSubmit={handleSubmit}
                validationSchema={emailValidationSchema}
              >
                {({ isFormDisabled, isSubmitting, error }) => (
                  <>
                    <TextField
                      label={intl.formatMessage({
                        defaultMessage: 'Email address',
                        description: 'Email screen label for email form field',
                      })}
                      name="email"
                      autofillName="email"
                      excludePII
                    />
                    {error && <SubmitError error={error} />}
                    <LoadingSpinner
                      isLoading={isSubmitting}
                      className="mt-10 flex justify-center mb-6"
                    >
                      <Button
                        analyticsEventName={ButtonClickedEvents.GetStarted}
                        type="submit"
                        disabled={isFormDisabled || isPreview}
                        className="block mt-4 mb-6"
                      >
                        <FormattedMessage
                          defaultMessage="Get started"
                          description="Email screen button text"
                        />
                      </Button>
                    </LoadingSpinner>
                    {MarketingPromo && <MarketingPromo />}
                    <Disclaimers
                      hasInstallments={hasInstallments}
                      hasSplitPay={hasSplitpay}
                      installmentsPaymentTypeValues={
                        exampleInstallmentsPaymentTypeValues
                      }
                      splitPayPaymentTypeValues={splitPayPaymentTypeValues}
                    />
                  </>
                )}
              </APIForm>
            </>
          );
        }}
      </IntroScreen>
    );
  };
